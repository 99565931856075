<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <b-loading :is-full-page="false" :active="isLoading"/>

            <div class="box b-shdw-3">

                <div class="level-item">
                    <h1 class="title is-1 page-title">BLOG</h1>
                </div>

                <p class="has-text-centered">Welcome to our blog! We love to post about our favourite travel destinations alongside any important travel news and updates. We hope you enjoy!</p>
                <hr>

                <b-pagination :total="total" :current.sync="current" :per-page="10" :range-before="1" :range-after="2"/>

                <!-- Blog container -->
                <div id="blog" class="box" oncontextmenu="return false;">

                    <!-- Individual blogs -->
                    <div v-for="(b, blogIndex) in blogs" :key="b.id">

                        <!-- Filter blog depending on date -->
                        <div v-if="blogIndex <= toPost && blogIndex >= fromPost && b.status === 'active'" class="box blog-section">

                            <router-link :to="{name:'blogView', params:{'id':b.url_name}}">
                                <div class="columns">
                                    <div class="column is-3">
                                        <img class="blog-img" :src="'https://covered2go.co.uk/content-block/images/covered2go/' + b.image_name" alt="blog image">
                                    </div>
                                    <div class="column blog-info">

                                        <p class="title blog-title is-5">{{ b.title }}</p>
                                        <p v-html="b.content.substr(0, 300) + '...'"/>

                                        <p class="tag">Posted on {{ $moment(b.post_date).format('DD/MM/Y') }}</p>

                                        <div class="columns">
                                            <div class="column is-offset-10 read-more-col has-text-centered">
                                                <button class="button is-info">Read more...</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </router-link>

                        </div>

                    </div>

                </div>
                <b-pagination :total="total" :current.sync="current" :per-page="10" :range-before="1" :range-after="2"/>

            </div>
        </div>
    </transition>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: "Blog",
        data() {
            return {
                current: 1,
                total: 0,
                isLoading: true
            }
        },
        mixins: [ contentAPI ],
        computed: {
            ...mapGetters({
                blogs: 'blogs',
            }),
            toPost() {
                return this.current * 10;
            },
            fromPost() {
                return this.toPost - 10;
            }
        },
        methods: {
            ...mapActions({
                setBlogs: 'setBlogs',
            }),
        },
        mounted() {
            //if(!this.blogs) {
                // Get blog posts from API
                this.getBlog('*').then((res) => {
                    const fetchedBlogs = res.data;
                    const blogList = fetchedBlogs.filter(b => {
                        return b.status === 'active';
                    });
                    this.setBlogs(blogList);
                    this.total = blogList.length;
                    this.isLoading = false;
                });
            /*} else {
                // Get stored blog posts from memory
                this.total = this.blogs[0].id;
                this.current = this.blogs[0].id;
                this.isLoading = false;
                this.totalPages = this.current;
            }*/
        }
    }
</script>

<style lang="scss" scoped>
    .level-item {
        padding: 0 0 20px 0;
    }
    #blog {
        .read-more-col {
            padding: 0 0 5px 0;
        }
        p {
            color: #313131;
        }
        margin: 15px 0;
    }
    .blog-section {
        border: 1px solid #bfbfbf;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .blog-section:hover {
        border: 1px solid black;
    }
    .blog-title {
        margin-bottom: 10px;
    }
    .blog-img {
        height: 190px;
        width: 240px;
        object-fit: cover;
    }
    .blog-info {
        .tag {
            margin-top: 10px;
        }
    }
</style>